export class I18nFallBackLabels {
    public static getFallbackLanguage(): string {
        let language =
            window.navigator.language ||
            window.navigator["browserLanguage"] ||
            window.navigator["userLanguage"];
        language = language || "en";
        language = language.split("-")[0]; // z.B. "de" oder "de-DE"
        return language;
    }

    public static getFallbackLabels(language: string): { [key: string]: string } {
        switch (language) {
            case "de":
                return {
                    "MobileApp.userLogin": "Benutzername",
                    "MobileApp.userPwd": "Passwort",
                    "MobileApp.Button.Login": "Anmelden",
                    "MobileApp.txtError.loginFailed": "Anmeldung fehlgeschlagen.",
                    "MobileApp.txtError.mobileAppDeactivated": "Mobile App ist deaktiviert.",
                    "MobileApp.txtError.noConnectionToServer": "Keine Antwort vom Server",
                    "MobileApp.AppLogin.UserInteractionRequired":
                        "Vorab Anmeldung über BCS erforderlich.",
                    "MobileApp.txtError.unknownServerError":
                        "Unbekannten Fehler vom Server erhalten",
                    "MobileApp.txtError.AppRequirementCheck.SecureHTTPConnectionRequired":
                        "Die App setzt eine sichere Verbindung (HTTPS) zu BCS mit einem gültigen Zertifikat voraus.",
                    "MobileApp.txtError.AppRequirementCheck.OfflineModeNotSupported":
                        "Der Browser unterstützt benötigte Offline Modus Funktionen nicht (IndexedDB).",
                    "IndexedDBOpenRequest.txtError.OpenDatabaseFailed":
                        "Fehler beim Öffnen der Datenbank des Browsers!",
                    "IndexedDBOpenRequest.txtError.FirefoxProfileCorrupt":
                        "Fehler beim Öffnen der Datenbank des Browsers!<br><br>" +
                        "Eine mögliche Ursache könnte ein beschädigtes Firefox-Benutzer-Profil sein.<br>" +
                        "Es gibt mehrere Möglichkeiten, das Problem zu beheben." +
                        "<ul>" +
                        "<li>Löschen des Dateiverzeichnisses unterhalb des Benutzerprofils, in dem Datenbankinhalte vom Firefox abgelegt sind " +
                        "(hierbei werden alle Offlinedaten anderer Webseiten gelöscht):<br>" +
                        "In der Adressleiste <b>about:support</b> eingeben, den Profilordner öffnen und dort das Unterverzeichnis <b>storage/default löschen</b>.</li>" +
                        "<li>Bereinigen des Benutzerprofiles (hierbei werden alle Einstellungen zurückgesetzt und alle AddOns gelöscht):<br>" +
                        "In der Adressleiste <b>about:support</b> eingeben und rechts oben auf die Schaltfläche <b>Firefox bereinigen ...</b> klicken.</li>" +
                        "<li>Verwenden eines anderen Browsers (empfohlen Google Chrome).</li>" +
                        "</ul>",
                    "IndexedDBOpenRequest.txtError.IndexedDBNotAvalaibleInPrivateMode":
                        "Fehler beim Öffnen der Datenbank des Browsers!<br><br>" +
                        "Manche Browser erlauben die Verwendung der browser-internen Datenbank nicht in privaten Browser-Fenstern.",
                    "MobileApp.InfoTextAfterLogoutAndClearSiteData":
                        "Sie sind abgemeldet und alle Offline-Daten wurden gelöscht.",
                    "MobileApp.ReloadApp": "Für erneutes einloggen: Mobile App neu laden",
                };
            case "en":
            default:
                return {
                    "MobileApp.userLogin": "Login Name",
                    "MobileApp.userPwd": "Password",
                    "MobileApp.Button.Login": "Log In",
                    "MobileApp.txtError.loginFailed": "Login failed.",
                    "MobileApp.txtError.mobileAppDeactivated": "Mobile App is deactivated.",
                    "MobileApp.txtError.noConnectionToServer": "No reply from server.",
                    "MobileApp.AppLogin.UserInteractionRequired": "Login to BCS required before.",
                    "MobileApp.txtError.unknownServerError": "Unknown error received from server.",
                    "MobileApp.txtError.AppRequirementCheck.SecureHTTPConnectionRequired":
                        "App requires a secure connection (HTTPS) with BCS using a valid certificate.",
                    "MobileApp.txtError.AppRequirementCheck.OfflineModeNotSupported":
                        "Browser does not support offline mode feature (IndexedDB).",
                    "IndexedDBOpenRequest.txtError.OpenDatabaseFailed":
                        "Error while opening the database of your browser!",
                    "IndexedDBOpenRequest.txtError.FirefoxProfileCorrupt":
                        "Error while opening the database of your browser!<br><br>" +
                        "This may be caused by a corrupt Firefox user profile.<br>" +
                        "You have multiple options to solve this problem." +
                        "<ul>" +
                        "<li>Delete the folder below your firefox user profile which contains the database contents of firefox " +
                        "(this will delete all offline data of other websites):<br>" +
                        "Open in your address bar <b>about:support</b>, click to open the folder of your firefox profile and " +
                        "delete the subfolder <b>storage/default</b>.</li>" +
                        "<li>Refresh your firefox profile (this will reset all preferences and remove all addons):<br>" +
                        "Open in your address bar <b>about:support</b> and click the button <b>Refresh Firefox ...</b>.</li>" +
                        "<li>Switch to another browser (e.g. Google Chrome).</li>" +
                        "</ul>",
                    "IndexedDBOpenRequest.txtError.IndexedDBNotAvalaibleInPrivateMode":
                        "Error while opening the database of your browser!<br><br>" +
                        "Some browsers do not allow using the browser internal database in a private mode browser window.",
                    "MobileApp.InfoTextAfterLogoutAndClearSiteData":
                        "You are logged out and all offline data has been deleted.",
                    "MobileApp.ReloadApp": "For Relogin: Reload Mobile App",
                };
        }
    }
}
